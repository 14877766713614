import React,{useState} from "react"
import Helmet from "../../components/helmet/helmet";
import NavBar from "../../components/navbar/navbar";
import "../index.scss"
import Footer from "../../components/footer/footer";
import MSmodal from "../../components/modal/MSmodal";

const Contact = (props) => {
  const { location } = props;
  const [modalShow, setModalShow] = useState(false);
  return (
    <div className="MainContainer">
      <Helmet />
      <NavBar location={location} />
      <div className="Content">contact</div>
      <button onClick={()=>setModalShow(true)}>TEST modal</button>
      <MSmodal show={modalShow} onHide={() => setModalShow(false)} >
        <div>Test<button>Click</button></div>
      </MSmodal>
      <Footer />
    </div>
  )
}

export default Contact;